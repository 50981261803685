
import { defineComponent } from "vue";
import jsonData from "@/json/events.json";
import Flicker from "@/components/common-elements/Flicker.vue";
import { Metatags } from "@/scripts/metatags";

export default defineComponent({
  name: "EventList",
  components: {
    Flicker,
  },
  mounted() {
    this.updateEvents();
    this.showFlickers = true;
    Metatags.change({
      title: this.$router.currentRoute.value.meta.title,
      description:
        "AcroYoga Calendar to plan your acro Holidays/training/trips :P",
      image: "",
    });
  },
  data() {
    return {
      adsData: jsonData,
      retreatsData: jsonData,
      workshopsData: jsonData,
      ttData: jsonData,
      allData: jsonData,
      showFlickers: false,
    };
  },
  methods: {
    getEvent(array: Array<any>, keyType: string, keyValue: string | string[]) {
      const arrayItem = array.filter((item: any) => {
        return item[keyType] === keyValue;
      })[0];
      if (!arrayItem) {
        return false;
      }
      return true;
    },
    updateEvents() {
      this.adsData = this.adsData.filter((event) => {
        return event.is_highlight;
      });
      // this.allData = this.allData.filter((event) => {
      //   return !event.is_highlight;
      // });
      this.retreatsData = this.retreatsData.filter((event) => {
        return this.getEvent(event.categories, "slug", "convention");
      });

      this.workshopsData = this.workshopsData.filter((event) => {
        return this.getEvent(event.categories, "slug", "workshop");
      });
      this.ttData = this.ttData.filter((event) => {
        return this.getEvent(event.categories, "slug", "teacher_training");
      });
      this.showFlickers = true;
    },
  },
});
