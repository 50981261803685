
import { defineComponent } from "vue";
import { event } from "vue-gtag";
import { Calendar, ArrowRightThick } from "mdue";

export default defineComponent({
  name: "Button",
  components: {
    Calendar,
    ArrowRightThick,
  },
  created() {
    this.disabledState = this.disabled == "false" ? false : true;
  },
  props: {
    text: String,
    cclass: { type: String, default: "btn-primary" },
    ctype: { type: String, default: "button" },
    action: Object,
    disabled: { type: String, default: "false" },
    icon: { type: String, default: null },
  },
  methods: {
    onClick() {
      if (!this.action) {
        return;
      }
      if (this.action.type === "route") {
        this.$router.push({
          name: this.action.value,
          params: { id: this.action.id },
          query: { referenceId: this.action.referenceId },
        });
      }
      if (this.action.type === "url") {
        let url = this.action.value;
        window.open(url);
      }
      if (this.action.type === "callback") {
        this.$emit("onEvent", this.action.value, this.action.close);
      }
      if (this.action.type === "gcalendar") {
        this.addToCalendar(this.action.gc);
      }
      if (this.action.gtag) {
        event(this.action.gtag.name, {
          event_category: this.action.gtag.category,
          event_label: this.action.gtag.label,
          value: this.action.gtag.value,
        });
      }
    },
    addToCalendar(object: any) {
      if (!object.startTime) return console.warn("prop: startTime is required");
      if (!object.endTime) return console.warn("prop: endTime is required");
      if (!object.title) return console.warn("prop: title is required");
      let startDate = this.formatDate(object.startTime);
      let endDate = this.formatDate(object.endTime)
        ? this.formatDate(object.endTime)
        : this.formatDate(object.startTime, true);

      this.googleCalendarLink = `http://www.google.com/calendar/event?action=TEMPLATE&text=${
        object.title || ""
      }&dates=${startDate}/${endDate}&details=${
        object.details || ""
      }&location=${object.location || ""}`;
      window.open(this.googleCalendarLink, "_blank");
    },
    formatDate(date: any, fixdate = false) {
      date = new Date(date);
      let day = date.getDate();
      if (fixdate) {
        day += 1;
      }
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      if (!day) {
        return null;
      }
      let formatted_date;
      if (hour === 0 && minutes === 0) {
        formatted_date =
          "" + year + this.zero_pad2(monthIndex + 1) + this.zero_pad2(day);
      } else {
        formatted_date =
          "" +
          year +
          this.zero_pad2(monthIndex + 1) +
          this.zero_pad2(day) +
          "T" +
          this.zero_pad2(hour) +
          this.zero_pad2(minutes) +
          "00Z";
      }
      return formatted_date;
    },
    zero_pad2(num: number) {
      if (num < 10) return "0" + num;
      return num;
    },
  },
  data() {
    return {
      disabledState: false,
      googleCalendarLink: "",
    };
  },
});
