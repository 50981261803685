
import { defineComponent } from "vue";
import Button from "@/components/common-elements/Button.vue";
import Flicking from "@egjs/vue3-flicking";

export default defineComponent({
  name: "Flicker",
  components: {
    Flicking: Flicking,
    Button,
  },
  created() {
    this.showData = true;
  },
  props: {
    eventsData: Object,
  },
  methods: {
    renderDate(date: string, pos = 0) {
      const dateExplode = date.split("-");
      return pos === 2
        ? dateExplode[2]
        : pos === 1
        ? this.months[Number(dateExplode[1]) - 1]
        : dateExplode[0];
    },
    returnteachers(array: Array<string>) {
      let teachers = "";
      array.forEach((x, i) => {
        teachers += x + ", ";
      });
      return array.length == 0 ? "" : teachers;
    },
    getImgUrl(image: string) {
      var images = require.context("@/assets/events_img/", false, /\.jpg$/);
      return images("./" + image + ".jpg");
    },
    convertDate(date: string, start = true) {
      const dateExplode = date.split("-");
      let newDate = new Date(
        Number(dateExplode[0]),
        Number(dateExplode[1]) - 1,
        Number(dateExplode[2]),
        0,
        0,
        0
      );
      if (!start) {
        newDate = new Date(
          Number(dateExplode[0]),
          Number(dateExplode[1]) - 1,
          Number(dateExplode[2]) + 1,
          23,
          59,
          59
        );
      }

      return newDate;
    },

    // goToUrl(item: any, e: any, gtag: any) {
    //   e.preventDefault();
    //   if (gtag) {
    //     event(gtag.name, {
    //       event_category: gtag.category,
    //       event_label: gtag.label,
    //       value: gtag.value,
    //     });
    //   }
    //   let url =
    //     item.link_type !== "route"
    //       ? item.link +
    //         "?utm_source=pmay_details&utm_media=website&utm_campaign=acroevents"
    //       : item.link;
    //   window.open(url);
    // },
  },
  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      showData: false,
      planMyAcroText:
        "<p>Check more Acro Events at <a href='https://planmyacro.site' >https://planmyacro.site</a> <br/>Presented to you by PlanMyAcro Team | 3NJ0Y</p>",
    };
  },
});
